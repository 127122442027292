import React from 'react';
import { CSSTransition, transit } from 'react-css-transition';

CSSTransition.childContextTypes = {};

// Used to create a dividing line between main and sub navigation
const NavigationDivider = (props) => (
  <div className="NavigationDivider">
    <CSSTransition
      active={['insights', 'services-and-upgrades', 'system'].includes(
        props.activePath,
      )}
      activeStyle={{ width: '100%' }}
      className="NavigationDivider-wrapper"
      defaultStyle={{ width: 0 }}
      enterStyle={{ width: transit('100%', 500, 'ease-in-out') }}
      leaveStyle={{ width: transit(0, 500, 'ease-in-out') }}
    >
      <hr />
    </CSSTransition>
  </div>
);

export default NavigationDivider;
