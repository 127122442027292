import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';

import BackgroundGradient from './BackgroundGradient';
import PageColumns from './PageColumns';

// Used for header section throughout website
class PageHeader extends Component {
  constructor(props) {
    super(props);

    this.updateViewport = this.updateViewport.bind(this);

    this.state = {
      viewportIsMobile: false,
    };
  }

  componentDidMount() {
    this.updateViewport();

    // Add resize event listener for viewport size changes
    window.addEventListener('resize', this.updateViewport);
  }

  componentWillUnmount() {
    // Remove resize event listener for viewport size changes
    window.removeEventListener('resize', this.updateViewport);
  }

  // Check if viewport width is smaller than 768px
  // Used to hide video and show image
  updateViewport() {
    this.setState({ viewportIsMobile: window.innerWidth < 768 });
  }

  render() {
    return (
      <header
        className={`Component PageHeader component-${this.props.id}${
          this.props.theme ? ` theme-${this.props.theme}` : ''
        }${this.props.noOverflow ? ' no-overflow' : ''}`}
        style={this.props.zIndex ? { zIndex: this.props.zIndex } : null}
      >
        <Grid
          className={`Component-wrapper ${this.props.componentClass}`}
          fluid
        >
          {this.props.backgroundGradients && (
            <BackgroundGradient gradients={this.props.backgroundGradients} />
          )}

          {this.props.image && (
            <Reveal
              {...this.props.image.animation}
              disabled={!this.props.image.animation}
            >
              <div
                className={`PageHeader-image${
                  this.props.image.class ? ` ${this.props.image.class}` : ''
                }`}
              >
                <img
                  alt={this.props.image.alt ? this.props.image.alt : ''}
                  src={this.props.image.src}
                />
              </div>
            </Reveal>
          )}

          {this.props.imageMobile && (
            <Reveal
              {...this.props.imageMobile.animation}
              disabled={!this.props.imageMobile.animation}
            >
              <div
                className={`PageHeader-image-mobile${
                  this.props.imageMobile.class
                    ? ` ${this.props.imageMobile.class}`
                    : ''
                }`}
              >
                <img
                  alt={
                    this.props.imageMobile.alt ? this.props.imageMobile.alt : ''
                  }
                  src={this.props.imageMobile.src}
                />
              </div>
            </Reveal>
          )}

          {this.props.video && !this.state.viewportIsMobile && (
            <Reveal
              {...this.props.video.animation}
              disabled={!this.props.video.animation}
            >
              <div
                className={`PageHeader-video ${
                  this.props.video.class ? ` ${this.props.video.class}` : ''
                }`}
              >
                <div className="video-overlay-dark" />
                <video
                  autoPlay
                  loop={this.props.video.loop}
                  muted
                  poster={this.props.video.poster}
                >
                  <source src={this.props.video.src} type="video/mp4" />
                </video>
              </div>
            </Reveal>
          )}

          <PageColumns {...this.props} />
        </Grid>
      </header>
    );
  }
}

PageHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  componentClass: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.object,
  imageMobile: PropTypes.object,
  row: PropTypes.object,
  theme: PropTypes.string,
  video: PropTypes.object,
  zIndex: PropTypes.number,
};

PageHeader.defaultProps = {
  theme: '',
  zIndex: 0,
};

export default PageHeader;
