import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';

import BackgroundGradient from './BackgroundGradient';
import PageLink from './PageLink';

const PageTeaser = (props) => (
  <div className={`PageTeaser${props.theme ? ` theme-${props.theme}` : ''}`}>
    <Row {...props.row}>
      <Col {...props.column}>
        {props.backgroundGradients && props.theme === 'dark' && (
          <BackgroundGradient gradients={props.backgroundGradients} />
        )}
        <h2 className={props.title.class}>{props.title.text}</h2>
        <p className={props.copy.class}>{props.copy.text}</p>
        <PageLink {...props.link} />
      </Col>
    </Row>
  </div>
);

PageTeaser.propTypes = {
  copy: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  theme: PropTypes.string,
  title: PropTypes.object.isRequired,
};

PageTeaser.defaultProps = {
  theme: 'white',
};

export default PageTeaser;
