import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'react-router-dom';

import Date from './Date';
import PageLink from './PageLink';

// Used for news preview (e.g. title, meta, link, featured image)
const CaseStudyPreview = (props) => (
  <Reveal duration={1000} effect="fade-in-up">
    <Row
      between="xs"
      className="CaseStudyPreview padtop-sm padbot-sm"
      start="xs"
    >
      <Col className="CaseStudyPreview-image" md={6} xs={12}>
        <Link title={props.link.title} to={props.link.url}>
          <img alt={props.image.alt} src={props.image.src} />
        </Link>
      </Col>
      <Col className="CaseStudyPreview-copy" md={5} xs={12}>
        <Date date={props.date} />
        <h3>{props.subTitle}</h3>
        <Link
          className="no-styling"
          title={props.link.title}
          to={props.link.url}
        >
          <h2 className="headline-thin">{props.title}</h2>
        </Link>
        <PageLink {...props.link} />
      </Col>
    </Row>
  </Reveal>
);

CaseStudyPreview.propTypes = {
  date: PropTypes.string.isRequired,
  image: PropTypes.object,
  link: PropTypes.object,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CaseStudyPreview;
