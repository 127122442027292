import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageTextTradeShowTeaser.module.scss';

const PageTextTradeShowTeaser = (props) => (
  <div className={styles.container}>
    <span className={styles.title}>{props.title}</span>
    <span className={styles.text}>{props.text}</span>
  </div>
);

PageTextTradeShowTeaser.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextTradeShowTeaser.defaultProps = {
  class: '',
};

export default PageTextTradeShowTeaser;
