import React from 'react';
import LazyLoad from 'react-lazyload';

// Used for images throughout website
const PageImageLayered = (props) => (
  <div
    className={`${props.parentClass} image-layered${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    {props.items.map((item, index) => (
      <LazyLoad key={index} height="100%" offset={500} once>
        <img
          alt={item.alt || ''}
          className={`image-layered-layer-${index}`}
          src={item.src}
        />
      </LazyLoad>
    ))}
  </div>
);

export default PageImageLayered;
