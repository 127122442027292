import React from 'react';
import DocumentMeta from 'react-document-meta';
import { Col, Grid, Row } from 'react-flexbox-grid';

import CaseStudyPreview from '../components/CaseStudyPreview';
import Footer from '../components/Footer';

const CaseStudies = (props) => (
  <DocumentMeta {...props.data[0].meta}>
    <div className="Page CaseStudies theme-bright" id="main">
      <section className="Component">
        <Grid className="Component-wrapper width-md" fluid>
          <Row around="xs" start="xs">
            <Col xs={12}>
              <h1 className="headline-thin">Our latest case studies</h1>
            </Col>
          </Row>
        </Grid>
      </section>
      <section className="Component">
        <Grid className="Component-wrapper width-sm" fluid>
          {props.data[1].items.map((item, index) => (
            <React.Fragment key={index}>
              <CaseStudyPreview key={index} {...item} />
              <hr />
            </React.Fragment>
          ))}
        </Grid>
      </section>
    </div>
    <Footer data={props.layout.footer} />
  </DocumentMeta>
);

export default CaseStudies;
