import React from 'react';
import PropTypes from 'prop-types';

// Used for unordered list blocks throughout website
const PageTextList = (props) => (
  <ul
    className={`${props.parentClass} list${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    {props.items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
);

PageTextList.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

PageTextList.defaultProps = {
  class: '',
};

export default PageTextList;
