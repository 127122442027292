import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PageLink from './PageLink';
import styles from './PageTextBullet.module.scss';
import PageTextCopy from './PageTextCopy';
import PageTextSubTitle from './PageTextSubTitle';

const ICON_MAP = {
  challenge: 'M8 24L11 15H2L16 0L13 9H22L8 24Z',
  fact: 'M0 12.1158L2.053 10.2188C4.454 11.3808 5.977 12.2638 8.675 14.1878C13.748 8.43081 17.101 5.50981 23.332 1.63281L24 3.16881C18.861 7.65281 15.098 12.6478 9.679 22.3668C6.336 18.4308 4.105 15.9208 0 12.1158Z',
  feather:
    'M1.34817 23.5733C4.82666 18.7246 13.6687 8.26782 19.3802 4.04419C19.732 3.79168 19.9558 3.63182 19.9558 3.63182C19.9558 3.63182 7.95523 16.3632 5.63275 19.5181C5.79515 19.6355 6.8201 20.4495 7.6615 20.234C7.17893 20.1167 6.92764 19.577 7.11707 19.5527C7.702 19.4736 8.25916 19.2981 8.75212 19.0377C8.75212 19.0377 7.44441 18.6791 7.44228 18.505C8.36321 18.4884 10.1118 18.188 10.5297 17.9866C12.6154 16.9818 15.754 15.9014 18.7835 12.7535C18.7835 12.7535 16.3477 12.8918 14.6588 12.5066C16.3485 12.393 19.6875 11.662 19.9072 11.4673C20.8153 10.6627 22.1358 8.50116 22.4473 7.43786C22.4473 7.43786 20.0499 7.97051 19.1973 8.11123C20.4697 7.54745 22.564 6.28942 22.7649 5.90243C23.2048 5.09918 23.5113 4.25448 23.6766 3.38933C23.698 3.25313 23.0027 3.75329 23.01 3.62659C23.0172 3.49992 23.6803 2.74226 23.7199 2.62974C23.9356 2.01581 24.1656 0.816852 23.8315 0.584887C23.4356 0.309929 19.4635 0.619631 15.8486 2.764C15.4589 2.99527 15.1868 5.26167 14.8338 6.21088C14.2383 5.53177 14.1528 3.51717 14.1528 3.51717C12.2195 4.56957 7.64168 8.20257 5.64064 11.108C5.07979 11.9224 4.34269 14.5077 4.57647 15.2881C3.70715 14.6704 3.62864 12.269 3.62864 12.269C2.79885 13.7075 2.5799 16.6618 3.13494 17.6414C3.21459 17.782 1.9489 18.0685 1.10737 17.6737C1.80012 18.6833 3.47133 18.442 3.47133 18.442L3.57024 18.5221C2.3807 19.9681 1.29116 21.4637 0.306397 23.0024C0.0485251 23.4737 -0.0491031 23.9899 0.0231321 24.5C0.553641 24.2804 1.0084 23.9623 1.34817 23.5733Z',
};

// Used for bullet points with check mark throughout website
const PageTextBullet = (props) => (
  <div className={classNames(props.parentClass, styles.container, props.class)}>
    <span className={classNames(styles.icon, styles[props.icon])}>
      {props.icon !== 'number' && (
        <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d={ICON_MAP[props.icon]} fill="currentColor" />
        </svg>
      )}
      {props.icon === 'number' && <span>{props.number}</span>}
    </span>

    <div className={styles.text}>
      {props.title && (
        <PageTextSubTitle
          class={styles.title}
          parentClass={styles.container}
          text={props.title}
        />
      )}

      {props.url ? (
        <PageLink
          class={styles.link}
          parentClass={styles.container}
          text={props.text}
          url={props.url}
        />
      ) : (
        <PageTextCopy parentClass={styles.container} text={props.text} />
      )}
    </div>
  </div>
);

PageTextBullet.propTypes = {
  class: PropTypes.string,
  icon: PropTypes.string.isRequired,
  parentClass: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
};

PageTextBullet.defaultProps = {
  class: '',
};

export default PageTextBullet;
