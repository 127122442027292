import React from 'react';
import { CookieBanner } from '@palmabit/react-cookie-law';

const CookieConsent = () => {
  const message =
    'We use cookies so that we can offer you the best possible website experience. This includes cookies which are necessary for the operation of the website and to manage our corporate commercial objectives, as well as other cookies which are used solely for anonymous statistical purposes, for more comfortable website settings, or for the display of personalized content. You are free to decide which categories you would like to permit. Please note that depending on the settings you choose, the full functionality of the website may no longer be available. Further information can be found in our Privacy Policy.';

  const onDeclineMarketing = () => {
    document.cookie =
      'rcl_marketing_consent=; Path=/; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  const onDeclinePreferences = () => {
    document.cookie =
      'rcl_preferences_consent=; Path=/; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };

  // const onDeclineStatistics = () => {
  //   window['ga-disable-UA-155729289-2'] = true;
  //   document.cookie = 'rcl_statistics_consent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // eslint-disable-next-line max-len
  //   document.cookie = '_gid=; Path=/; domain=.nice-system.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // eslint-disable-next-line max-len
  //   document.cookie = '_gat=; Path=/; domain=.nice-system.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // eslint-disable-next-line max-len
  //   document.cookie = '_ga=; Path=/; domain=.nice-system.com; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // };

  return (
    <CookieBanner
      acceptButtonText="Use necessary cookies only"
      className="CookieConsent"
      managePreferencesButtonText="Manage cookies"
      marketingDefaultChecked={false}
      marketingOptionText={null}
      message={message}
      necessaryOptionText={null}
      policyLink="/privacy-policy"
      preferencesDefaultChecked={false}
      preferencesOptionText={null}
      privacyPolicyLinkText="Privacy Policy"
      showDeclineButton={false}
      showStatisticsOption={false}
      statisticsDefaultChecked={false}
      statisticsOptionText={null}
      styles={{
        button: {},
        buttonWrapper: {},
        checkbox: {},
        container: {},
        dialog: {},
        message: {
          fontSize: '100%',
        },
        optionLabel: {},
        optionWrapper: {},
        policy: {},
        selectPane: {},
      }}
      wholeDomain
      onAccept={() => null}
      onDeclineMarketing={onDeclineMarketing}
      onDeclinePreferences={onDeclinePreferences}
    />
  );
};

export default CookieConsent;
