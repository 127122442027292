import React from 'react';
import PropTypes from 'prop-types';

// Used for copy main title headlines throughout website
const PageTextTitle = (props) => (
  <h1
    className={`${props.parentClass} main-title${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    {props.text}
  </h1>
);

PageTextTitle.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextTitle.defaultProps = {
  class: '',
};

export default PageTextTitle;
