import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import BackgroundGradient from './BackgroundGradient';
import styles from './PageSwitchable.module.scss';
import Toggle from './Toggle';

const PageSwitchable = (props) => {
  const [isToggleChecked, setIsToggleChecked] = useState(
    props.initialState === 'on',
  );

  return (
    <section
      className={classNames(
        'Component',
        'PageSwitchable',
        `component-${props.id}`,
        props.theme && `theme-${props.theme}`,
      )}
      style={props.zIndex ? { zIndex: props.zIndex } : null}
    >
      <Grid
        className={classNames('Component-wrapper', props.componentClass)}
        fluid
      >
        {props.backgroundGradients && (
          <BackgroundGradient gradients={props.backgroundGradients} />
        )}

        <Row between="md" middle="md">
          <Col lg={6}>
            <Reveal duration={1000} effect="fade-in-up">
              <div>
                <h2>{props.title}</h2>
                <p>{props.copy}</p>
                <Toggle
                  labelOff={props.labelOff}
                  labelOn={props.labelOn}
                  value={isToggleChecked}
                  onChange={setIsToggleChecked}
                />
              </div>
            </Reveal>
          </Col>

          <Col className="padtop-lg-mobile" lg={5}>
            <Reveal delay={250} duration={1000} effect="fade-in-up">
              <TransitionGroup className={styles.imageWrap}>
                <CSSTransition
                  key={isToggleChecked ? 'on' : 'off'}
                  classNames="image-fade"
                  timeout={1000}
                >
                  <img
                    {...(isToggleChecked ? props.imageOn : props.imageOff)}
                  />
                </CSSTransition>
              </TransitionGroup>
            </Reveal>
          </Col>
        </Row>
      </Grid>
    </section>
  );
};

PageSwitchable.propTypes = {
  backgroundGradients: PropTypes.array,
  componentClass: PropTypes.string,
  copy: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  imageOn: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
  }),
  imageOff: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string.isRequired,
  }),
  initialState: PropTypes.oneOf(['off', 'on']).isRequired,
  labelOff: PropTypes.string.isRequired,
  labelOn: PropTypes.string.isRequired,
  theme: PropTypes.string,
  title: PropTypes.string.isRequired,
  zIndex: PropTypes.number,
};

export default PageSwitchable;
