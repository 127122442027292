import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import IconArrow from './IconArrow';

// Used for links throughout website
const PageLink = (props) => (
  <Link
    className={`${props.parentClass ? `${props.parentClass} ` : ''}link${
      props.class ? ` ${props.class}` : ''
    }`}
    title={props.title}
    to={props.url}
  >
    {props.icon === 'arrow' && <IconArrow />}
    <span className="link-text">{props.text}</span>
  </Link>
);

PageLink.propTypes = {
  class: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

PageLink.defaultProps = {
  text: 'Learn more',
};

export default PageLink;
