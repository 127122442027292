import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

const currentYear = new Date().getFullYear();

// Used to render items in page footer
class FooterItem extends Component {
  constructor(props) {
    super(props);

    this.renderItem = this.renderItem.bind(this);
  }

  // Render footer item according to defined type
  renderItem(item) {
    switch (item.type) {
      case 'divider':
        return <hr />;

      case 'facebook':
        return (
          <a
            className="Footer-social-media"
            href={item.link}
            rel={item.target === '_blank' && 'noopener noreferrer'}
            target={item.target}
            title={item.alt}
          >
            <svg className="Footer-social-media-facebook" viewBox="0 0 24 24">
              <path d="M18.4728.005,15.1148,0C11.3423,0,8.9043,2.3183,8.9043,5.9064V8.63H5.528a.51.51,0,0,0-.528.49V13.065a.51.51,0,0,0,.528.4894H8.9043v9.9562A.51.51,0,0,0,9.4323,24h4.4051a.51.51,0,0,0,.528-.4894V13.5544H18.313a.51.51,0,0,0,.5281-.4894l.0016-3.9457a.4724.4724,0,0,0-.1547-.346.55.55,0,0,0-.3736-.1436h-3.949V6.3211c0-1.11.2853-1.6728,1.8448-1.6728l2.2621-.0007A.51.51,0,0,0,19,4.1582V.4944A.51.51,0,0,0,18.4728.005Z" />
            </svg>
          </a>
        );

      case 'info':
        // Replace [CURRENTYEAR] tag by current year
        const info = item.text.split('[CURRENTYEAR]').join(currentYear);
        return <p className="info-text">{info}</p>;

      case 'logo':
        return <img alt={item.alt} src={item.src} />;

      case 'link':
        return (
          <a
            href={item.link}
            rel={item.target === '_blank' && 'noopener noreferrer'}
            target={item.target}
            title={item.alt}
          >
            {item.title}
          </a>
        );

      case 'linkedIn':
        return (
          <a
            className="Footer-social-media"
            href={item.link}
            rel={item.target === '_blank' && 'noopener noreferrer'}
            target={item.target}
            title={item.alt}
          >
            <svg className="Footer-social-media-linkedin" viewBox="0 0 24 24">
              <path d="M.5,24h5V8H.5ZM3.0019,6A3,3,0,1,1,6,3,2.999,2.999,0,0,1,3.0019,6ZM24,24H18.8691V16.4071c0-1.81-.0326-4.1395-2.6658-4.1395-2.6707,0-3.0782,1.973-3.0782,4.01V24H8V8.3871h4.9185v2.1348H12.99A5.4514,5.4514,0,0,1,17.8437,8C23.04,8,24,11.2325,24,15.4375Z" />
            </svg>
          </a>
        );

      case 'navLink':
        return (
          <NavLink activeClassName="active" exact to={item.link}>
            {item.title}
          </NavLink>
        );

      case 'youTube':
        return (
          <a
            className="Footer-social-media"
            href={item.link}
            rel={item.target === '_blank' && 'noopener noreferrer'}
            target={item.target}
            title={item.alt}
          >
            <svg className="Footer-social-media-youtube" viewBox="0 0 28 21">
              <path d="M13.885308,0.666666667 C13.885308,0.666666667 5.19316774,0.66671135 3.01996526,1.23860676 C1.8532986,1.55886819 0.892201522,2.5198759 0.57194009,3.7094184 C4.4683333e-05,5.88262096 -1.15134238e-16,10.3888888 0,10.3888888 C0,10.3888888 4.4683333e-05,14.9180327 0.57194009,17.0683592 C0.892201522,18.2579018 1.83042279,19.1961231 3.01996526,19.5163844 C5.21604374,20.1111557 13.885308,20.111111 13.885308,20.111111 C13.885308,20.111111 22.5782078,20.1110663 24.7514103,19.5391709 C25.9409529,19.2189093 26.8791742,18.2806884 27.1994355,17.0911457 C27.7713308,14.9179432 27.7713755,10.4116754 27.7713755,10.4116754 C27.7713755,10.4116754 27.7942068,5.88262096 27.1994355,3.7094184 C26.8791742,2.5198759 25.9409529,1.58165467 24.7514103,1.26139323 C22.5782078,0.666621992 13.885308,0.666666667 13.885308,0.666666667 Z M11.1175131,6.22580296 L18.3461369,10.3888888 L11.1175131,14.5519747 L11.1175131,6.22580296 Z" />
            </svg>
          </a>
        );

      default:
        return <div />;
    }
  }

  render() {
    return this.renderItem(this.props);
  }
}

export default FooterItem;
