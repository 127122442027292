import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Reveal from 'react-reveal/Reveal';

// Used to embed videos throughout website
const PageVideoFramed = (props) => {
  const videoRef = useRef(null);

  const getClasses = () => {
    const classes = [props.parentClass, 'video-framed'];

    if (props.class) classes.push(props.class);

    return classes.join(' ');
  };

  return (
    <LazyLoad height="100%" offset={500} once>
      <Reveal
        {...props.animation}
        fraction={props.fraction}
        onReveal={() => videoRef.current.play()}
      >
        <div className={getClasses()}>
          <img alt="" src="/assets/images/general_frame_ipad.png" />
          <video ref={videoRef} loop={props.loop} muted>
            <source src={props.src} type="video/mp4" />
          </video>
        </div>
      </Reveal>
    </LazyLoad>
  );
};

PageVideoFramed.propTypes = {
  class: PropTypes.string,
  loop: PropTypes.bool,
  parentClass: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

PageVideoFramed.defaultProps = {
  class: null,
  loop: false,
};

export default PageVideoFramed;
