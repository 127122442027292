import React from 'react';
import PropTypes from 'prop-types';

const PageDisruptor = (props) => (
  <aside className={`Component PageDisruptor component-${props.id}`}>
    <div className="Component-wrapper width-md">
      <div className="wrapper">
        <img
          alt="Portrait of Rabih Jamileh"
          src="/assets/images/insights_case_study_dial_testimonial_image.png"
        />

        <div className="contentWrapper">
          <span className="title">Upgrade now!</span>

          <section className="copy">
            <span>
              <strong>Call us</strong>{' '}
              <a href="tel:+18443342224">+1 844 334 2224</a>
            </span>
            <br />
            <span>
              or{' '}
              <a href={props.link} title="Upgrade page">
                check our upgrade page
              </a>
            </span>
          </section>
        </div>
      </div>
    </div>
  </aside>
);

PageDisruptor.propTypes = {
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

export default PageDisruptor;
