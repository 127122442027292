import React from 'react';
import PropTypes from 'prop-types';

// Used for info text blocks throughout website
const PageTextInfo = (props) => (
  <div
    className={`${props.parentClass} info info-text${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    {props.text}
  </div>
);

PageTextInfo.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextInfo.defaultProps = {
  class: '',
};

export default PageTextInfo;
