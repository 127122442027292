import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';

// Used for minimalistic image slideshow throughout website
const PageImageSlider = (props) => (
  <Slider
    arrows={false}
    className={`${props.parentClass} image-slider${
      props.class ? ` ${props.class}` : ''
    }`}
    dots
    fade
    infinite
    speed={250}
    swipe
  >
    {props.slides.map((slide, index) => (
      <LazyLoad key={index} height="100%" offset={500} once>
        <img alt="" src={slide} />
      </LazyLoad>
    ))}
  </Slider>
);

PageImageSlider.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
};

export default PageImageSlider;
