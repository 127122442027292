import React from 'react';
import PropTypes from 'prop-types';

// Used for copy section headlines throughout website
const PageTextSectionTitle = (props) => (
  <h5
    className={`${props.parentClass} sectionTitle${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    {props.text}
  </h5>
);

PageTextSectionTitle.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

PageTextSectionTitle.defaultProps = {
  class: '',
};

export default PageTextSectionTitle;
