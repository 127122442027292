import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { withRouter } from 'react-router-dom';

import Footer from './Footer';
import NewsTeaser from './NewsTeaser';
import PageContact from './PageContact';
import PageContent from './PageContent';
import PageDevices from './PageDevices';
import PageDisruptor from './PageDisruptor';
import PageExplosion from './PageExplosion';
import PageHeader from './PageHeader';
import PageLightUI from './PageLightUI';
import PageQuote from './PageQuote';
import PageSlider from './PageSlider';
import PageSwitchable from './PageSwitchable';

// Used to translate settings from page data into desired layout
class Page extends Component {
  constructor(props) {
    super(props);

    this.firstSection = React.createRef();

    this.handleScrollTo = this.handleScrollTo.bind(this);
    this.renderComponents = this.renderComponents.bind(this);
  }

  // Handle scroll to first section of site
  handleScrollTo() {
    window.scrollTo({
      top: this.firstSection.current.offsetTop,
      behavior: 'smooth',
    });
  }

  renderComponents() {
    return this.props.data.map((component, index) => {
      if (index <= 0) return;

      const props = {
        key: index,
        ...component,
      };

      // Give second section of page firstSection prop (for handleScrollTo()-function)
      if (index === 2) {
        props.refProp = this.firstSection;
      }

      switch (component.type) {
        case 'contact':
          return (
            <PageContact
              {...component}
              {...props}
              contact={this.props.layout.contact}
            />
          );
        case 'content':
          return <PageContent {...component} {...props} />;
        case 'devices':
          return <PageDevices {...component} {...props} />;
        case 'disruptor':
          return <PageDisruptor {...component} {...props} />;
        case 'explosion':
          return <PageExplosion {...component} {...props} />;
        case 'header':
          return (
            <PageHeader
              {...component}
              {...props}
              handleScrollTo={this.handleScrollTo}
            />
          );
        case 'lightUI':
          return <PageLightUI {...component} {...props} />;
        case 'newsTeaser':
          return (
            <NewsTeaser
              {...component}
              {...props}
              latestArticles={this.props.layout.latestArticles.entries}
            />
          );
        case 'quote':
          return <PageQuote {...component} {...props} />;
        case 'slider':
          return <PageSlider {...component} {...props} />;
        case 'switchable':
          return <PageSwitchable {...component} {...props} />;
        default:
          return null;
      }
    }, this);
  }

  render() {
    return (
      <DocumentMeta {...this.props.data[0].meta}>
        <div
          className={`Page ${this.props.data[0].class} theme-${this.props.data[0].theme}`}
          id="main"
        >
          {this.renderComponents()}
        </div>
        <Footer data={this.props.layout.footer} />
      </DocumentMeta>
    );
  }
}

export default withRouter(Page);
