import './css/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();
