import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import styles from './Toggle.module.scss';

const Toggle = (props) => {
  const id = uuidv4();
  const [internalValue, setInternalValue] = useState(props.defaultValue);
  const isControlled = typeof props.value !== 'undefined';

  const isChecked = isControlled ? props.value : internalValue;

  const handleChange = ({ currentTarget }) => {
    if (!isControlled) setInternalValue(currentTarget.checked);
    if (props.onChange) props.onChange(currentTarget.checked);
  };

  return (
    <div className={styles.container}>
      {props.labelOff && (
        <span className={styles.labelOff}>{props.labelOff}</span>
      )}

      <label
        aria-checked={isChecked}
        className={classNames(styles.toggle, isChecked && styles.checked)}
        htmlFor={id}
      >
        <input
          checked={isControlled ? props.value : isChecked}
          className={styles.input}
          id={id}
          type="checkbox"
          onChange={handleChange}
        />
        <span className={styles.track} />
      </label>

      {props.labelOn && <span className={styles.labelOn}>{props.labelOn}</span>}
    </div>
  );
};

Toggle.propTypes = {
  defaultValue: PropTypes.bool,
  labelOff: PropTypes.string,
  labelOn: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

Toggle.defaultProps = {
  defaultValue: false,
};

export default Toggle;
