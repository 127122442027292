import React from 'react';

// Used for sub nav toggle button
const NavigationSubToggle = (props) => (
  <div className="NavigationSub-toggle">
    <svg
      className="icon-open"
      viewBox="0 0 20 20"
      onClick={() => props.toggleNav()}
    >
      <path d="M12.1712,10,5.7474,2.6585a1,1,0,1,1,1.5052-1.317l7,8a1,1,0,0,1,0,1.317l-7,8a1,1,0,1,1-1.5052-1.317Z" />
    </svg>

    <svg
      className="icon-close"
      viewBox="0 0 20 20"
      onClick={() => props.toggleNav()}
    >
      <path d="M18.4853,16.9675a1.25,1.25,0,0,1-1.7678,1.7678L10,12.0178,3.2825,18.7353a1.25,1.25,0,0,1-1.7678-1.7678L8.2322,10.25,1.5147,3.5325A1.25,1.25,0,0,1,3.2825,1.7647L10,8.4822l6.7175-6.7175a1.25,1.25,0,0,1,1.7678,1.7678L11.7678,10.25Z" />
    </svg>
  </div>
);

export default NavigationSubToggle;
