import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';

import BackgroundGradient from './BackgroundGradient';
import styles from './PageQuote.module.scss';

const PageQuote = (props) => (
  <section
    className={classNames(
      'Component',
      'PageQuote',
      `component-${props.id}`,
      props.theme && `theme-${props.theme}`,
    )}
    style={props.zIndex ? { zIndex: props.zIndex } : null}
  >
    <Grid
      className={classNames('Component-wrapper', props.componentClass)}
      fluid
    >
      {props.backgroundGradients && (
        <BackgroundGradient gradients={props.backgroundGradients} />
      )}

      <Row center="xs" className={styles.wrap}>
        <Col md={9}>
          <Reveal duration={1000} effect="fade-in-up">
            <div>
              <svg
                className={styles.quotationMarks}
                fill="none"
                height="174"
                viewBox="0 0 136 174"
                width="136"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 115.183L31.7605 0L57.006 0L26.0599 108.648H48.8623L48.8623 174H0L0 115.183ZM78.994 115.183L110.754 0L136 0L105.054 108.648H127.856V174L78.994 174V115.183Z"
                  fill="white"
                  fillOpacity="0.4"
                />
              </svg>

              <span className={styles.quote}>{props.quote}</span>
              <span className={styles.quotee}>— {props.quotee}</span>
            </div>
          </Reveal>
        </Col>
      </Row>
    </Grid>
  </section>
);

PageQuote.propTypes = {
  backgroundGradients: PropTypes.array,
  componentClass: PropTypes.string,
  id: PropTypes.number.isRequired,
  theme: PropTypes.string,
  quote: PropTypes.string,
  quotee: PropTypes.string,
  zIndex: PropTypes.number,
};

export default PageQuote;
