import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-flexbox-grid';
import LazyLoad from 'react-lazyload';
import Reveal from 'react-reveal/Reveal';

import BackgroundGradient from './BackgroundGradient';
import PageColumns from './PageColumns';

// Used only on system/user-interface to show different devices
class PageDevices extends Component {
  constructor(props) {
    super(props);

    this.renderImages = this.renderImages.bind(this);
  }

  renderImages(elements) {
    return elements.map((element, index) => (
      <LazyLoad key={index} height="100%" offset={500} once>
        <Reveal {...element.animation} disabled={!element.animation}>
          <img
            alt={element.alt}
            className="PageDevices-image"
            src={element.src}
          />
        </Reveal>
      </LazyLoad>
    ));
  }

  render() {
    return (
      <section
        className={`Component PageDevices component-${this.props.id}${
          this.props.theme && ` theme-${this.props.theme}`
        }`}
        style={{ zIndex: this.props.zIndex }}
      >
        <Grid
          className={`Component-wrapper ${this.props.componentClass}`}
          fluid
        >
          {this.props.backgroundGradients && (
            <BackgroundGradient gradients={this.props.backgroundGradients} />
          )}

          <div className="PageDevices-images">
            {this.renderImages(this.props.images)}
          </div>

          <PageColumns {...this.props} />
        </Grid>
      </section>
    );
  }
}

PageDevices.propTypes = {
  backgroundGradient: PropTypes.array,
  columns: PropTypes.array.isRequired,
  componentClass: PropTypes.string.isRequired,
  images: PropTypes.array,
  id: PropTypes.number.isRequired,
  row: PropTypes.object,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

PageDevices.defaultProps = {
  theme: '',
  zIndex: 0,
};

export default PageDevices;
