import React from 'react';
import { NavLink } from 'react-router-dom';

// Used for navigation link items
const NavigationItem = (props) => (
  <NavLink activeClassName="active" className={props.class} to={props.link}>
    {props.title}
  </NavLink>
);

export default NavigationItem;
