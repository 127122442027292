import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Reveal from 'react-reveal/Reveal';

// Used to embed videos throughout website
class PageVideo extends Component {
  constructor(props) {
    super(props);

    this.video = React.createRef();
    this.playVideo = this.playVideo.bind(this);
    this.updateViewport = this.updateViewport.bind(this);

    this.state = {
      viewportIsMobile: false,
    };
  }

  componentDidMount() {
    this.updateViewport();

    // Add resize event listener for viewport size changes
    window.addEventListener('resize', this.updateViewport);
  }

  componentWillUnmount() {
    // Remove resize event listener for viewport size changes
    window.removeEventListener('resize', this.updateViewport);
  }

  // Play video only if viewport is not mobile
  playVideo() {
    if (!this.state.viewportIsMobile) {
      this.video.current.play();
    }
  }

  // Check if viewport width is smaller than 768px
  // Used to hide video and show image
  updateViewport() {
    this.setState({ viewportIsMobile: window.innerWidth < 768 });
  }

  render() {
    return (
      <LazyLoad height="100%" offset={500} once>
        <Reveal
          {...this.props.animation}
          fraction={this.props.fraction}
          onReveal={this.playVideo}
        >
          <div
            className={`${this.props.parentClass} video${
              this.props.class ? ` ${this.props.class}` : ''
            }`}
          >
            <video
              ref={this.video}
              loop={this.loop}
              muted
              poster={this.props.poster}
            >
              <source src={this.props.src} type="video/mp4" />
            </video>
          </div>
          <div
            className={`${this.props.parentClass} video-image-mobile ${this.props.imageMobileClass}`}
          >
            <img alt="" src={this.props.imageMobile} />
          </div>
        </Reveal>
      </LazyLoad>
    );
  }
}

PageVideo.propTypes = {
  class: PropTypes.string,
  imageMobile: PropTypes.string,
  imageMobileClass: PropTypes.string,
  loop: PropTypes.bool,
  parentClass: PropTypes.string.isRequired,
  poster: PropTypes.string,
  src: PropTypes.string.isRequired,
};

PageVideo.defaultProps = {
  class: null,
  imageMobileClass: '',
  loop: false,
  poster: null,
};

export default PageVideo;
