import React from 'react';
import PropTypes from 'prop-types';

// Used for contact information blocks throughout website
const PageTextContact = (props) => (
  <div
    className={`${props.parentClass} contact${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    <h4 className="contact-title">{props.title}:</h4>

    <a
      alt={props.alt}
      className="contact-text"
      href={props.link}
      rel="noopener noreferrer"
      target={props.target}
    >
      {props.text}
    </a>
  </div>
);

PageTextContact.propTypes = {
  alt: PropTypes.string,
  class: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.string.isRequired,
  parentClass: PropTypes.string.isRequired,
};

PageTextContact.defaultProps = {
  alt: '',
  class: '',
  target: '_self',
};

export default PageTextContact;
