import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-flexbox-grid';

import BackgroundGradient from './BackgroundGradient';
import PageColumns from './PageColumns';
import styles from './PageContent.module.scss';

// Used to display page content in different sized columns (1/1, 1/2, 1/3 etc.)
const PageContent = (props) => (
  <section
    ref={props.refProp}
    className={`Component PageContent component-${props.id}${
      props.theme ? ` theme-${props.theme}` : ''
    }${props.noOverflow ? ' no-overflow' : ''}`}
    style={props.zIndex ? { zIndex: props.zIndex } : null}
  >
    <Grid className={`Component-wrapper ${props.componentClass}`} fluid>
      {props.backgroundGradients && (
        <BackgroundGradient gradients={props.backgroundGradients} />
      )}

      {props.backgroundImage && (
        <img
          alt=""
          className={styles.backgroundImage}
          src={props.backgroundImage}
        />
      )}

      <PageColumns {...props} />
    </Grid>
  </section>
);

PageContent.propTypes = {
  backgroundGradients: PropTypes.array,
  backgroundImage: PropTypes.string,
  columns: PropTypes.array.isRequired,
  componentClass: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  refProp: PropTypes.object,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

PageContent.defaultProps = {
  refProp: null,
  theme: '',
  zIndex: 0,
};

export default PageContent;
