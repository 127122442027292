import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';

import BackgroundGradient from './BackgroundGradient';
import PageSliderArrow from './PageSliderArrow';
import PageSliderNav from './PageSliderNav';
import PageSliderText from './PageSliderText';

// Used for slideshows throughout website
class PageSlider extends Component {
  constructor(props) {
    super(props);

    this.slideTo = this.slideTo.bind(this);

    this.state = {
      currentSlide: 0,
    };
  }

  slideTo(index) {
    // eslint-disable-next-line react/no-string-refs
    this.refs.slider.slickGoTo(index);
    this.setState({ currentSlide: index });
  }

  render() {
    return (
      <section
        className={`Component PageSlider component-${this.props.id} theme-${this.props.theme}`}
        style={{ zIndex: this.props.zIndex }}
      >
        <div className={`Component-wrapper ${this.props.componentClass}`}>
          {this.props.backgroundGradients && (
            <BackgroundGradient gradients={this.props.backgroundGradients} />
          )}

          <div className="PageSlider-slider">
            <PageSliderArrow
              direction="prev"
              // eslint-disable-next-line react/no-string-refs
              onClick={() => this.refs.slider.slickPrev()}
            />

            <Slider
              // eslint-disable-next-line react/no-string-refs
              ref="slider"
              afterChange={(index) => this.setState({ currentSlide: index })}
              arrows={false}
              dots
              fade
              infinite
              speed={250}
              swipe
            >
              {this.props.slides.map((slide, index) => (
                <LazyLoad key={index} height="100%" offset={500} once>
                  <div className={`PageSlider-slide ${slide.imageClass || ''}`}>
                    <img
                      alt=""
                      src={slide.image}
                      style={{ maxWidth: slide.imageWidth || '100%' }}
                    />
                  </div>
                </LazyLoad>
              ))}
            </Slider>

            <PageSliderArrow
              direction="next"
              // eslint-disable-next-line react/no-string-refs
              onClick={() => this.refs.slider.slickNext()}
            />
          </div>

          {!this.props.settings?.noNav && (
            <>
              <PageSliderNav
                {...this.state}
                slides={this.props.slides}
                slideTo={this.slideTo}
              />
              <PageSliderText {...this.props.slides[this.state.currentSlide]} />
            </>
          )}
        </div>
      </section>
    );
  }
}

PageSlider.propTypes = {
  backgroundGradients: PropTypes.array,
  componentClass: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

PageSlider.defaultProps = {
  theme: '',
  zIndex: 0,
};

export default PageSlider;
