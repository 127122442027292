import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import Reveal from 'react-reveal/Reveal';

import PageColumnsContent from './PageColumnsContent';

// Used to display content in columns
const PageColumns = (props) => (
  <div className="content">
    <Row {...props.row}>
      {props.columns.map((column, index) => {
        const columnProps = {
          content: column.content,
          handleScrollTo: props.handleScrollTo,
          parentClass: `PageColumns column-${index}`,
        };

        return (
          <Col key={index} {...column.settings}>
            <Reveal {...column.animation} disabled={!column.animation}>
              <PageColumnsContent {...columnProps} />
            </Reveal>
          </Col>
        );
      })}
    </Row>
  </div>
);

PageColumns.propTypes = {
  backgroundGradients: PropTypes.array,
  columns: PropTypes.array.isRequired,
  row: PropTypes.object,
  theme: PropTypes.string,
  zIndex: PropTypes.number,
};

PageColumns.defaultProps = {
  row: {},
  theme: '',
  zIndex: 0,
};

export default PageColumns;
