import React from 'react';
import PropTypes from 'prop-types';

import PageTextCopy from './PageTextCopy';
import PageTextSubTitle from './PageTextSubTitle';

// Used only on contact page for map description
const PageTextMap = (props) => (
  <div
    className={`${props.parentClass} map${
      props.class ? ` ${props.class}` : ''
    }`}
  >
    <span>
      <span className="map-icon">
        <span className="map-number">{props.id}</span>
      </span>
    </span>
    <span className="map-text">
      <PageTextSubTitle parentClass="map" text={props.title} />
      <PageTextCopy parentClass="map" text={props.copy} />
    </span>
  </div>
);

PageTextMap.propTypes = {
  class: PropTypes.string,
  parentClass: PropTypes.string.isRequired,
};

PageTextMap.defaultProps = {
  class: '',
};

export default PageTextMap;
